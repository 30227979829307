exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-page-js": () => import("./../../../src/pages/preview-page.js" /* webpackChunkName: "component---src-pages-preview-page-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview-post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-pages-pub-js": () => import("./../../../src/pages/pub.js" /* webpackChunkName: "component---src-pages-pub-js" */),
  "component---src-pages-run-a-pub-vacancies-js": () => import("./../../../src/pages/run-a-pub/vacancies.js" /* webpackChunkName: "component---src-pages-run-a-pub-vacancies-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-templates-beer-js": () => import("./../../../src/templates/beer.js" /* webpackChunkName: "component---src-templates-beer-js" */),
  "component---src-templates-list-category-js": () => import("./../../../src/templates/list-category.js" /* webpackChunkName: "component---src-templates-list-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-pub-js": () => import("./../../../src/templates/pub.js" /* webpackChunkName: "component---src-templates-pub-js" */),
  "component---src-templates-tenancy-js": () => import("./../../../src/templates/tenancy.js" /* webpackChunkName: "component---src-templates-tenancy-js" */)
}

