/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onServiceWorkerUpdateReady = () => window.location.reload();

export function onRouteUpdate( { location, prevLocation}) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
        window.dataLayer.push({
            event: `gatsby-route-change`
        });
    }, 50);
}
