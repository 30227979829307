import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import themeData from "./muiTheme.json";
import themeOptions from "../../../.brew-cache/theme.json";
import { modifyMui } from "../modifyMui";
import arrow from "../../../src/images/right-arrow.png";
import brickWall from "../../../src/images/brick-texture.png";
import linkArrow from "../../../src/images/link-arrow.png";
import brassTexture from "../../../src/images/brass-texture.png";
import iconEmail from "../../../src/images/icon-email.png";
import iconPDF from "../../../src/images/icon-pdf.png";
import iconPhone from "../../../src/images/icon-phone.png";
import iconForm from "../../../src/images/icon-form.png";

import BrassTextureGreen from "../../../src/images/brass-texture-green.png";
import iconEmailGreen from "../../../src/images/icon-email-black.png";
import iconPhoneGreen from "../../../src/images/icon-phone-black.png";
import iconFormGreen from "../../../src/images/icon-form-black.png";

const themeName = "Robinsons - Brand Theme";
const modifiedTheme = modifyMui(themeData, themeOptions);
let theme = createTheme({ ...modifiedTheme, themeName });
theme = responsiveFontSizes(theme, { factor: 3 });

// Custom theme styles here or import them from wherever if they need to be broken

// Reusable

theme.headerBefore = {
  "&::before": {
    width: 40,
    height: 4,
    content: '""',
    display: "block",
    marginBottom: 16,
    backgroundColor: theme.palette.primary.main,
  },
};

// Global

theme.buttonTheme = {
  "& button": {
    textAlign: "left",
    minWidth: 200,
    justifyContent: "space-between",
    "&::after": {
      content: '""',
      backgroundImage: "url(" + arrow + ")",
      width: 17,
      height: 17,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      display: "inline-block",
      backgroundPosition: "center",
      marginLeft: 16,
    },
    transition: "background-color .3s ease-in-out",
    "&:hover, &:focus": {
      backgroundColor: "#72ce9b",
    },
  },
};

theme.headerTheme = {
  marginBottom: 12,
  marginTop: 24,
  letterSpacing: "0.056em",
  "&.MuiTypography-h1": {
    textTransform: "uppercase",
  },
  "&.MuiTypography-h2": {
    textTransform: "uppercase",
  },
  "&.MuiTypography-h3": {
    textTransform: "uppercase",
  },
};

// Blocks

theme.bannerCalloutCtaTheme = {
  "& .mobile-bg.gatsby-image-wrapper": {
    height: "550px !important",
    "&::after": {
      display: "none",
    },
  },
  "& .desktop-bg.gatsby-image-wrapper": {
    "&::after": {
      display: "none",
    },
  },
  "& .content-wrap": {
    display: "block",
    paddingTop: 55,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingTop: 0,
      alignItems: "normal",
    },
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontWeight: "bold",
    fontSize: 40,
    letterSpacing: "0.056em",
    color: theme.palette.common.white,
    lineHeight: "1.2",
    textShadow: "0 5px 16px rgba(0,0,0,0.7)",
    textTransform: "uppercase",
    maxWidth: 430,
    marginTop: 0,
    marginBottom: 30,
    [theme.breakpoints.up("md")]: {
      fontSize: 56,
      lineHeight: "1.15",
    },
  },
};

theme.bannerMessageTheme = {
  "& .mobile-bg.gatsby-image-wrapper": {
    width: "100vw !important",
    height: "220px !important",
    "&::after": {
      display: "none",
    },
  },
  "& .desktop-bg.gatsby-image-wrapper": {
    "&::after": {
      display: "none",
    },
    "& > div": {
      paddingBottom: "32% !important",
    },
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontWeight: "bold",
    fontSize: 40,
    letterSpacing: "0.056em",
    color: theme.palette.common.white,
    lineHeight: "1.2",
    textShadow: "0 5px 16px rgba(0,0,0,0.7)",
    textTransform: "uppercase",
    marginTop: 0,
    bottom: "50%",
    transform: "translateY(50%)",
    [theme.breakpoints.up("md")]: {
      fontSize: 56,
      lineHeight: "1.15",
    },
  },
};

theme.blogBannerTheme = {
  "& .mobile-bg.gatsby-image-wrapper": {
    "&::after": {
      display: "none",
    },
  },
  "& .desktop-bg.gatsby-image-wrapper": {
    "&::after": {
      display: "none",
    },
    "& > div": {
      paddingBottom: "32% !important",
    },
  },
};

theme.blogPagePostsTheme = {
  "& .listing-title": {
    "& h2": {
      ...theme.headerBefore,
    },
  },
};

theme.breakoutGalleryIntroTheme = {
  "& .micro-header": {
    fontSize: 16,
    letterSpacing: "0.056em",
    textTransform: "none",
    fontStyle: "italic",
    ...theme.headerBefore,
  },
  "& .bold-con": {
    [theme.breakpoints.up("lg")]: {
      paddingRight: 16,
    },
    "& p": {
      marginBottom: 16,
      fontWeight: "normal",
      fontSize: 14,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
  "& .standard-con": {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 16,
    },
    "& p": {
      marginBottom: 16,
      fontSize: 14,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
};

theme.calloutCtaTheme = {
  // backgroundImage: "url(" + BrassTexture + ")",
  backgroundSize: "cover",
  backgroundPosition: "center",
  "& p": {
    textTransform: "uppercase",
  },
  "& button": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
};

theme.cardBlockTheme = {
  backgroundColor: theme.palette.background.default,
  borderRadius: 0,
  "& .con-wrap": {
    padding: "40px 30px",
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      ...theme.headerBefore,
      fontSize: 24,
      letterSpacing: "0.056em",
      lineHeight: "1.4",
      [theme.breakpoints.up("md")]: {
        fontSize: 32,
        lineHeight: 1.2,
      },
    },
    "& p": {
      fontSize: 14,
      letterSpacing: "0.024em",
      lineHeight: 2,
    },
  },
};

theme.cardRowTheme = {
  "&.full-width": {
    width: "calc(100vw - 18px)",
    marginLeft: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up("lg")]: {
      width: "calc(100vw - 72px)",
    },
  },
  "&.has-background": {
    paddingTop: 75,
    paddingBottom: 75,
    position: "relative",
    "&::before": {
      position: "absolute",
      top: 0,
      left: "50%",
      bottom: 0,
      width: "100vw",
      transform: "translateX(-50vw)",
      backgroundImage: "url(" + brickWall + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      content: '""',
      display: "block",
    },
  },
};

theme.contactCalloutTheme = {
  "&::before": {
    backgroundImage: "url(" + brassTexture + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  "& .text": {
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      ...theme.headerBefore,
      fontSize: 24,
      margin: "0 0 36px",
      letterSpacing: "0.056em",
      lineHeight: 1.25,
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: 32,
        marginBottom: 24,
      },
    },
    "& p": {
      fontSize: 14,
      letterSpacing: "0.032em",
      lineHeight: 2,
      marginBottom: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
  "& .buttons": {
    "& > a button": {
      [theme.breakpoints.up("md")]: {
        margin: "0 25px 0 !important",
      },
      "&::after": {
        width: 20,
        height: 20,
        [theme.breakpoints.up("md")]: {
          marginBottom: 10,
          marginLeft: 0,
        },
      },
      "&.icon-email::after, &.icon-mail::after": {
        backgroundImage: "url(" + iconEmail + ") !important",
      },
      "&.icon-phone::after": {
        backgroundImage: "url(" + iconPhone + ") !important",
      },
      "&.icon-pdf::after": {
        backgroundImage: "url(" + iconPDF + ") !important",
      },
      "&.icon-form::after": {
        backgroundImage: "url(" + iconForm + ") !important",
      },
    },
  },
  "&.green-buttons": {
    "&:before": {
      backgroundImage: "url(" + BrassTextureGreen + ") !important",
    },
    "& .buttons > a > button": {
      backgroundColor: "#72ce9b !important",
      color: theme.palette.common.black,
      transition: "none",
      "&:hover, &:focus": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark + " !important",
      },
      "&.icon-phone:not(:hover, :focus):after": {
        backgroundImage: "url(" + iconPhoneGreen + ") !important",
      },
      "&.icon-mail:not(:hover, :focus):after, &.icon-email:not(:hover, :focus):after": {
        backgroundImage: "url(" + iconEmailGreen + ") !important",
      },
      "&.icon-form:not(:hover, :focus):after": {
        backgroundImage: "url(" + iconFormGreen + ") !important",
      },
    },
  },
};

theme.featuredIconsTheme = {
  "& h2": {
    textTransform: "uppercase",
    ...theme.headerBefore,
    margin: "0 0 70px",
    fontSize: 24,
    letterSpacing: "0.056em",
    lineHeight: 1.25,
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
      letterSpacing: "0.06em",
      lineHeight: 1.5,
    },
  },
};

theme.featuredIconsInnerTheme = {
  position: "relative",
  "& p": {
    borderTop: "1px solid",
    borderColor: theme.palette.secondary.contrastText,
    paddingTop: 10,
    fontSize: 14,
    fontStyle: "italic",
    lineHeight: 2,
    letterSpacing: "0.032em",
  },
};

theme.featuredTextTheme = {
  backgroundImage: "url(" + brassTexture + ")",
  backgroundSize: "100% 100%",
  "& > p": {
    color: theme.palette.common.white,
  },
};

theme.goodToGoTheme = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "30px 8px",
  margin: "0 auto 34px",
  maxWidth: 960,
  width: "100%",
  boxSizing: "border-box",
  transform: "none",
  backgroundColor: "transparent",
  "&::before": {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "50%",
    width: "100vw",
    transform: "translateX(-50%)",
    content: '""',
    backgroundColor: theme.palette.secondary.main + "99",
    border: "5px solid",
    borderColor: theme.palette.secondary.main,
    boxShadow: "0 6px 21px rgba(0,0,0,0.75)",
  },
  "& .gatsby-image-wrapper": {
    width: "32px !important",
    marginRight: 10,
    flex: "0 0 32px",
    height: 32,
    [theme.breakpoints.up("md")]: {
      width: "50px !important",
      marginRight: 40,
      flex: "0 0 50px",
      height: 50,
    },
  },
  "& p": {
    fontSize: 12,
    lineHeight: "2",
    letterSpacing: "0",
    borderLeft: "1px solid",
    borderColor: theme.palette.secondary.contrastText,
    paddingLeft: 14,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 24,
      fontSize: 16,
      lineHeight: "1.5",
    },
  },
  "&::after": {
    display: "none",
  },
  "&.alert-bg": {
    backgroundColor: "transparent",
    "&::before": {
      backgroundColor: "#A7193099",
      borderColor: "#A71930",
    },
    "& p": {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      width: "auto",
    },
  },
};

theme.imageBreakdownTheme = {
  paddingTop: 34,
  paddingBottom: 34,
  [theme.breakpoints.up("md")]: {
    paddingTop: 74,
    paddingBottom: 140,
  },
  "& .title-wrap": {
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      textTransform: "uppercase",
      letterSpacing: "0.06em",
      fontSize: 32,
      lineHeight: "1.1",
      marginBottom: 50,
      fontFamily: theme.subHeaderFont,
      [theme.breakpoints.up("md")]: {
        fontSize: 40,
        lineHeight: "1.35",
        marginBottom: 40,
      },
      "&::before": {
        width: 40,
        height: 4,
        content: '""',
        display: "block",
        margin: "0 auto 16px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
};

theme.imageBreakdownInnerTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    textTransform: "uppercase",
    letterSpacing: "0.03em",
    fontSize: 18,
    lineHeight: 1.5,
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      lineHeight: 1.25,
    },
  },
};

theme.imageContentRowsTheme = {};

theme.imageContentRowsInnerTheme = {
  "& .con-wrap": {
    padding: "55px 24px 35px",
    [theme.breakpoints.up("md")]: {
      padding: 80,
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      ...theme.headerBefore,
      fontSize: 24,
      lineHeight: 1.25,
      letterSpacing: "0.056em",
      textTransform: "uppercase",
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        fontSize: 32,
      },
    },
    "& p": {
      fontSize: 14,
      lineHeight: 2,
      letterSpacing: "0.024em",
      marginTop: 32,
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
};

theme.imageLinkSliderTheme = {
  "&::before": {
    backgroundImage: "url(" + brassTexture + ")",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  "& h2": {
    fontSize: 28,
    lineHeight: "1.25",
    fontWeight: "400",
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
};

theme.introGalleryTheme = {
  marginTop: 75,
  marginBottom: 50,
  [theme.breakpoints.up("md")]: {
    marginTop: 160,
    marginBottom: 130,
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 32,
    lineHeight: 1.2,
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      // maxWidth: 360,
      fontSize: 48,
      lineHeight: 1.2,
      letterSpacing: "0.06em",
    },
    ...theme.headerBefore,
  },
};

theme.latestPostsTheme = {};

theme.statColumnsTheme = {
  "& h2": {
    letterSpacing: "0.056em",
    lineHeight: 1.25,
    margin: "0 0 60px",
    textTransform: "uppercase",
    ...theme.headerBefore,
  },
  "& p": {
    borderTop: "1px solid",
    borderColor: theme.palette.secondary.contrastText,
    paddingTop: 5,
    marginBottom: 36,
  },
};

theme.tabContentTheme = {
  "& .MuiTabs-indicator": {
    [theme.breakpoints.up("md")]: {
      height: 3,
    },
  },
  "& .MuiBox-root": {
    "&::before": {
      borderTop: "1px solid",
      borderColor: theme.palette.secondary.main,
      [theme.breakpoints.up("md")]: {
        borderTop: "2px solid",
      },
    },
  },
};

theme.testimonialTheme = {
  padding: "50px 0 60px 36px",
  [theme.breakpoints.up("md")]: {
    paddingLeft: 75,
  },
  "&::before": {
    position: "absolute",
    border: "3px solid",
    borderRight: "none",
    borderColor: theme.palette.primary.main,
    content: '""',
    display: "block",
    width: 40,
    top: 0,
    left: 0,
    bottom: 0,
  },
};

theme.testimonialSliderTheme = {
  "& h2": {
    ...theme.headerBefore,
    fontSize: 24,
    lineHeight: 1.25,
    letterSpacing: "0.056em",
    marginBottom: 36,
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      marginBottom: 70,
      fontSize: 32,
    },
  },
};

theme.thickIntroTheme = {
  padding: "22px 0",
  [theme.breakpoints.up("md")]: {
    padding: "108px 0",
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontFamily: theme.subHeaderFont,
    fontSize: 32,
    lineHeight: "1.25",
    fontWeight: "600",
    marginBottom: 32,
    textTransform: "uppercase",
    letterSpacing: "0.048em",
    [theme.breakpoints.up("md")]: {
      fontSize: 48,
      lineHeight: "1.15",
    },
    ...theme.headerBefore,
  },
  "& p": {
    letterSpacing: "0.032em",
  },
};

theme.twoColInnerTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    textTransform: "uppercase",
    ...theme.headerBefore,
  },
  "& > p.arrow-link a": {
    fontSize: 16,
    color: theme.palette.primary.main,
    letterSpacing: "0.06em",
    lineHeight: 1.2,
    display: "inline-block",
    textTransform: "uppercase",
    textDecoration: "none",
    fontFamily: theme.headerFont,
    transition: "color .3s ease-in-out",
    "&::after": {
      width: 26,
      height: 13,
      display: "inline-block",
      marginLeft: 15,
      backgroundImage: "url(" + linkArrow + ")",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      content: '""',
      transition: "margin-left .3s ease-in-out",
    },
    "&:hover, &:focus": {
      color: theme.palette.primary.dark,
      "&::after": {
        marginLeft: 25,
      },
    },
  },
};

theme.videoBannerMessageTheme = {
  "& .mobile-bg.gatsby-image-wrapper": {
    width: "100vw !important",
    height: "220px !important",
    "&::after": {
      display: "none",
    },
  },
  "& .desktop-bg.gatsby-image-wrapper": {
    "&::after": {
      display: "none",
    },
    "& > div": {
      paddingBottom: "32% !important",
    },
  },
  "& .content-wrap": {
    bottom: "50%",
    transform: "translateY(50%)",
    maxWidth: 430,
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontWeight: "bold",
    fontSize: 40,
    letterSpacing: "0.056em",
    color: theme.palette.common.white,
    lineHeight: "1.2",
    textShadow: "0 5px 16px rgba(0,0,0,0.7)",
    textTransform: "uppercase",
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 56,
      lineHeight: "1.15",
    },
  },
};

// PRODUCT & SHOP PAGES

theme.productDescriptionTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    textTransform: "uppercase",
    ...theme.headerBefore,
  },
};

export default theme;
