import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import themeOptions from "../../.brew-cache/theme.json"

// Switch based on the activeTheme
let theme = '';

switch(themeOptions.activeTheme){
  case 'Robinsons - Brand Site Theme':
  default:
      theme = require('./rob-brand-site').default;
      break;
}

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}